<template>
  <div>
    <div class="top">
      <a-button v-if="isShowAdd" icon="plus" class="margin_right20" type="primary" @click="addModal = true">添加</a-button>
    </div>
    <!-- 表格 -->
    <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"  :loading="tableLoading">
      <span slot="order" slot-scope="operation, record, index">
        {{ index + 1 }}
      </span>
      <img v-viewer class="avatar" slot="cover_url" slot-scope="operation, record" :src="record.cover_url" >
      <span slot="operation" slot-scope="operation, record">
        <a-popconfirm
          v-if="isShowDel" 
          title="确定删除此推荐课程"
          ok-text="确定"
          cancel-text="取消"
          @confirm="delConfirm(record.id)"
        >
          <a href="#">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    
    <!-- 添加课程 -->
    <add-recommend v-if="addModal" :visible="addModal" @cancel="addModal = false" @ok="addOk" />
  </div>
</template>

<script>
import { codeFn } from '@/utils/tools'
import { audioColumns } from './constans'
import AddRecommend from './addRecommend.vue'
import { getRecommendListAPI, delRecommendCourseAPI } from '@/request/api/indexManage'

export default {
  components: { AddRecommend },
  created() {
    this.fetchData()
    this.isShowDel = codeFn('delRecommendBtn')
    this.isShowAdd = codeFn('addRecommendBtn')
  },
  data() {
    return {
      isShowAdd:false,
      isShowDel:false,
      tableLoading: true,
      columns: audioColumns,
      tableData: [],
      addModal: false
    };
  },
  methods: {
    async fetchData() {
      this.tableLoading = true
      const { data, code } = await getRecommendListAPI()
      this.tableLoading = false
      if (code !== 200) return
      this.tableData = data
    },
    async delConfirm(id) {
      const { code } = await delRecommendCourseAPI({ id: id })
      if (code !== 200) return
      this.$message.success('删除成功')
      this.fetchData()
    },
    addOk() {
      this.addModal = false
      this.fetchData()
    }
  }
}
</script>

<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  .search-box {
    display: flex;
    justify-content: space-around;
  }
}
.avatar {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
</style>
