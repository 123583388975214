<template>
  <div>
    <a-modal
      title="添加推荐"
      :visible="visible"
      :destroy-on-close="true"
      @ok="ok"
      @cancel="$emit('cancel')"
    >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol" >
      <a-form-model-item ref="title" label="课程" prop="courseNo">
        <a-button type="primary" @click="courseModal = true">{{ tableList.length === 0 ? '添加课程' : '修改课程'}}</a-button>
        <div v-if="tableList.length > 0">
          <ProductCard :table-list="tableList" />
        </div>
      </a-form-model-item>
      <a-form-model-item ref="sort" label="排序" prop="sort">
        <a-input-number :min="0" v-model="form.sort" />
      </a-form-model-item>
    </a-form-model>
    </a-modal>

    <!-- 课程列表 -->
    <RadioModal
      v-if="courseModal"
      :modalVisible="courseModal"
      :isShowMember="false"
      :type="0"
      :selectedRowKeys="selectedRowKeys"
      @cancel="courseModal = false"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import RadioModal from '@/components/courseMember/RadioModal'
import ProductCard from '@/components/product/ProductCard'
import { addRecommendCourseAPI } from '@/request/api/indexManage'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  components: { RadioModal, ProductCard },
  data () {
    return {
      courseModal: false,
      selectedRowKeys: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      rules: {
        courseNo:[{ required: true, message: "请选择课程", trigger: ['blur', 'change']}],
        sort: [{ required: true, message: "排序", trigger: "blur" }],
      },
      form: {
        courseNo: '',
        sort: ''
      },
      tableList: []
    }
  },
  methods: {
    // 弹窗中确认按钮事件
    handleOk(selectedRowKeys, selectedRows) {
      this.courseModal = false
      if (selectedRowKeys.length === 0) return
      this.form.courseNo = selectedRows.courseNo
      this.tableList = [selectedRows]
      this.selectedRowKeys = selectedRowKeys
    },
    ok() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return
        const { code } = await addRecommendCourseAPI({ ...this.form })
        if (code !== 200) return
        this.$message.success('添加成功')
        this.$emit('ok')
      })
    }
  }
}
</script>