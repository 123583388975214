<template>
  <a-tabs class="padding24 color_white" :activeKey="activeKey" @change="tabsChange">
    <a-tab-pane key="1" tab="标题设置">
      <Setting />
    </a-tab-pane>
    <a-tab-pane key="2" tab="列表内容">
      <List />
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import Setting from './setting.vue'
import List from './list.vue'

export default {
  name: 'Recommendation',
  components: { Setting, List },
  data () {
    return {
      activeKey: '1'
    }
  },
  methods: {
    tabsChange (activeKey) {
      this.activeKey = activeKey;
    }
  }
}
</script>