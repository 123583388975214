export const audioColumns = [
  {
    title: '序号',
    dataIndex: 'order',
    key: 'order',
    scopedSlots: { customRender: 'order' },
    width: 80
  },
  {
    title: '课程封面',
    dataIndex: 'cover_url',
    key: 'cover_url',
    scopedSlots: { customRender: 'cover_url' },
    width: 150
  },
  {
    title: '课程名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '操作',
    fixed: 'right',
    width: 150,
    key: 'operation',
    dataIndex: 'operation',
    scopedSlots: {customRender: 'operation'},
  },
]
