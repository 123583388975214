<template>
  <div>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol" >
      <a-form-model-item ref="title" label="标题" prop="title">
          <a-input v-model="form.title" placeholder="请输入标题">
            <!-- <div slot="addonAfter">{{ form.title.length }}/30</div> -->
          </a-input>
      </a-form-model-item>
      <a-form-model-item ref="descb" label="标题" prop="descb">
        <a-textarea v-model="form.descb" placeholder="请输入简介" :rows="4" show-count :maxlength="255" />
      </a-form-model-item>
    </a-form-model>
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button type="primary" @click="handleSave">保存</a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import FooterToolBar from "@/components/FooterToolbar"
import { baseMixin } from '@/store/app-mixin'
import { getRecommendInfoAPI, setRecommendInfoAPI } from '@/request/api/indexManage'
export default {
  mixins: [baseMixin],
  components: { FooterToolBar },
  data() {
    return {
      labelCol: { span: 1 },
      wrapperCol: { span: 8 },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
      },
      form: {
        title: '',
        descb: ''
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { code, data } = await getRecommendInfoAPI()
      if (code !== 200) return
      this.form = data
    },
    // 保存的按钮
    handleSave() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return
        const { code } = await setRecommendInfoAPI({ ...this.form })
        if (code !== 200) return
        this.$message.success('修改成功')
      })
    },
  }
}
</script>